import { Grid, Paper, Typography } from '@mui/material';

import { useCostingReviewContext } from './costings-review-context';

import { CostingSection } from '@/types';

function CostingsBanner() {
  const { costingSections } = useCostingReviewContext();

  return (
    <Paper>
      <Grid container padding={3}>
        <Grid xs={12} paddingBottom={2} item>
          <Typography variant="subtitle1">Budget</Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={3} item>
            <Paper sx={{ bgcolor: 'primary.light' }}>
              <Grid item padding={1.5}>
                <Typography variant="subtitle2">Total cost</Typography>
              </Grid>
              <Grid item padding={1.5} textAlign="end">
                <Typography variant="h4">$Auto Calculate</Typography>
              </Grid>
            </Paper>
          </Grid>
          {costingSections.map((section: CostingSection) => (
            <Grid xs={3} item key={section.id}>
              <Paper sx={{ bgcolor: 'primary.light' }}>
                <Grid item padding={1.5}>
                  <Typography variant="subtitle2">{section.name || 'Section Name'}</Typography>
                </Grid>
                <Grid item padding={1.5} textAlign="end">
                  <Typography variant="h4">$Auto Calculate</Typography>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CostingsBanner;
