import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEventListener } from 'usehooks-ts';

import BudgetTabs from './budget-tabs';

import { ErmCommand, ErmCommandNames } from '@/types';
import { ActionType, useBudgetDispatchContext, useBudgetStateContext } from '@forms/contexts/budget-context';
import SummaryTiles from '@forms/features/summary/summary-tiles';
import preawardServiceApi from '@forms/services/preaward-api';

function Budget() {
  const [searchParams] = useSearchParams();
  const dispatch = useBudgetDispatchContext();
  const { isDirty: changesDetected, sections, fundingDetails } = useBudgetStateContext();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const formId = searchParams.get('formId')!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pageId = searchParams.get('pageId')!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userProjectFormId = searchParams.get('userProjectFormId')!;
  const submissionId = searchParams.get('submissionId');

  const postMessageToParent = (command: string, value: boolean) => window.parent.postMessage({ command, value }, '*');

  const saveForm = async (updatedPageId: string) => {
    const success = await preawardServiceApi.saveCostingsInForm(
      formId,
      updatedPageId,
      userProjectFormId,
      sections,
      fundingDetails,
    );
    postMessageToParent(ErmCommandNames.formSaved, success);
  };

  useEffect(() => {
    if (changesDetected) {
      postMessageToParent(ErmCommandNames.isDirty, true);
    }
  }, [changesDetected]);

  const handleMessage = (event: MessageEvent<ErmCommand>) => {
    if (event.data.command === ErmCommandNames.saveForm && changesDetected) {
      saveForm(event.data.pageId).catch(() => {});
      dispatch({
        type: ActionType.SAVE_FORM,
      });
    }
  };

  useEventListener('message', handleMessage);

  useEffect(() => {
    const getSummaryTable = async () => {
      const summaryData = await preawardServiceApi.getSummary(formId, pageId, userProjectFormId, fundingDetails);
      dispatch({ type: ActionType.SET_SUMMARY_TABLE, payload: summaryData.data });
    };

    getSummaryTable().catch(() => {});
  }, [formId, pageId, userProjectFormId, fundingDetails, changesDetected, dispatch]);

  return (
    <Grid container sx={{ px: 2 }}>
      <Grid item xs={12}>
        <SummaryTiles />
      </Grid>
      <Grid item xs={12} id="tabs">
        <BudgetTabs
          formId={formId}
          pageId={pageId}
          userProjectFormId={userProjectFormId}
          submissionId={submissionId}
          readOnly={submissionId !== null}
        />
      </Grid>
    </Grid>
  );
}

export default Budget;
