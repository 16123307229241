import { Box, Button, Grid, Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { DeleteSectionIcon } from './costing-delete';
import CostingsCategory from './costings-category';
import { useCostingReviewContext } from './costings-review-context';

import { CostingSection } from '@/types';

function CostingsSection(props: { section: CostingSection }) {
  const { deleteSection, updateSection, addCategory } = useCostingReviewContext();

  const { section } = props;
  const [sectionName, setSectionName] = useState(section.name);

  const handleAddCostingCategory = () => {
    addCategory(section.id);
  };

  useEffect(() => {
    const updatedSection: CostingSection = {
      id: section.id,
      name: sectionName || '',
      categories: section.categories,
    };
    updateSection(updatedSection);
  }, [sectionName, section.id, section.categories, updateSection]);

  return (
    <Paper elevation={2} sx={{ padding: 4 }}>
      <Grid container data-testid="section">
        <Grid xs={10} item>
          <TextField
            id={`cost-section-name-${section.id}`}
            label="Enter Section Name"
            value={sectionName}
            onChange={(event) => setSectionName(event.target.value)}
            sx={{ marginBottom: 2 }}
            size="small"
          />
        </Grid>
        <Grid xs={2} item>
          <Box display="flex" justifyContent="flex-end">
            <DeleteSectionIcon callback={() => deleteSection(section.id)} />
          </Box>
        </Grid>
        {section.categories.map((category) => (
          <Grid xs={12} key={category.id} item>
            <Box>
              <CostingsCategory category={category} sectionId={section.id} />
            </Box>
          </Grid>
        ))}
        <Grid>
          <Box sx={{ ml: 4 }}>
            <Button variant="contained" color="secondary" onClick={() => handleAddCostingCategory()}>
              Add Category
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CostingsSection;
