import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';

import CostingsTable from './table';

import { useBudgetStateContext } from '@/forms/contexts/budget-context';
import { CostEntity } from '@/types';
import preawardServiceApi from '@forms/services/preaward-api';
import { alignValue, costingGridColumnDefinition, flattenRows, formatValueCell } from '@forms/utils';

interface DataGridProps {
  sectionName: string;
  parentRow: GridRowModel;
  open: boolean;
  handleClose: () => void;
}

function CostingTableDialog(props: Readonly<DataGridProps>) {
  const { sectionName, parentRow, open, handleClose } = props;
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [loading, setLoading] = useState(true);
  const { fundingDetails } = useBudgetStateContext();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  function setCostingColumns(data: CostEntity[]) {
    const keys = Object.keys(data[0]).filter((key) => key !== 'Children');
    const cols: GridColDef[] = keys.map((key) => ({
      ...costingGridColumnDefinition,
      field: key,
      headerName: key,
      type: 'number',
      headerAlign: alignValue(key),
      align: alignValue(key),
      renderCell: (params) => formatValueCell(params.value as number, { withCurrency: true }),
    }));

    setColumns(cols);
  }

  useEffect(() => {
    const getCostingsTable = async () => {
      const data = await preawardServiceApi.getCostingsTable(sectionName, parentRow, fundingDetails);
      setCostingColumns(data.data);
      setRows(flattenRows(data.data));
      setLoading(false);
    };

    if (open && parentRow) {
      setRows([]);
      setLoading(true);
      getCostingsTable().catch(() => {
        setLoading(false);
      });
    }
  }, [open, sectionName, parentRow, fundingDetails]);

  return (
    <Dialog
      id="costing-table-dialog"
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="costing-table-dialog-title"
      aria-describedby="costing-table-dialog-description"
    >
      <DialogTitle>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            {parentRow.DisplayName ?? parentRow.Name ?? parentRow.Item ?? parentRow.University} Costing Table
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button variant="dialogAction" endIcon={<CloseIcon />} onClick={handleClose}>
              Done
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Skeleton data-testid="loadingSkeleton" variant="rectangular" />
        ) : (
          <CostingsTable columns={columns} rows={rows} uniqueKey="Type" />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default CostingTableDialog;
