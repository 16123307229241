import { Box, Button, Grid, Tab, Tabs, Typography, styled } from '@mui/material';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';

import FundingIcon from '@/assets/icons/funding.svg';
import FundingDetailsModal from '@/forms/components/funding-details-popup';
import { ActionType, useBudgetDispatchContext, useBudgetStateContext } from '@forms/contexts/budget-context';
import { FacilitiesAndServicesContextProvider } from '@forms/contexts/facilities-services-context';
import SectionTab from '@forms/features/section/section-tab';
import SummaryTable from '@forms/features/summary/summary-table';
import preawardServiceApi from '@forms/services/preaward-api';

function TabPanel(
  props: Readonly<{
    children: ReactNode;
    index: number;
    value: number;
    prefix: string;
    key?: string;
  }>,
) {
  const { children, value, index, prefix } = props;

  return (
    <Grid role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`${prefix}-${index}`}>
      {value === index && <Box>{children}</Box>}
    </Grid>
  );
}

const FundingButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.grey[300]}`,
  fontWeight: 700,
  borderRadius: theme.shape.borderRadius * 1.5,
  padding: theme.spacing(0.5, 1),
}));

function BudgetTabs(
  props: Readonly<{
    formId: string;
    pageId: string;
    userProjectFormId: string;
    submissionId?: string | null;
    readOnly: boolean;
  }>,
) {
  const [value, setValue] = useState(0);
  const { sections, isDirty } = useBudgetStateContext();
  const dispatch = useBudgetDispatchContext();

  const [fundingDialogOpen, setFundingDialogOpen] = useState(false);

  const handleOpen = () => setFundingDialogOpen(true);
  const handleClose = () => setFundingDialogOpen(false);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getCostingsData = async () => {
      const costingsData =
        props.readOnly && props.submissionId
          ? await preawardServiceApi.getSubmission(props.formId, props.pageId, props.submissionId)
          : await preawardServiceApi.getCostings(props.formId, props.pageId, props.userProjectFormId);

      dispatch({
        type: ActionType.ADD_SECTIONS,
        payload: costingsData.sections,
      });
      if (costingsData.pricingSettings) {
        dispatch({
          type: ActionType.SET_FUNDING_DETAILS,
          payload: { data: costingsData.pricingSettings, isDirty: false },
        });
      }
    };

    getCostingsData().catch(() => {});
  }, [dispatch, props.formId, props.pageId, props.readOnly, props.submissionId, props.userProjectFormId]);

  return (
    <Grid container>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Tabs value={value} onChange={handleChange}>
            <Tab id="summary" label="Summary" key="0" />
            {sections.map((section, i) => (
              <Tab
                label={section.displayName ?? section.name}
                key={section.id}
                id={`tab-${i + 1}`}
                aria-controls={`tabpanel-${i}`}
              />
            ))}
          </Tabs>
          {isDirty && (
            <Typography
              variant="caption"
              sx={{
                color: 'warning.main',
                p: 1,
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              Changes not saved
            </Typography>
          )}
        </Box>
        <TabPanel value={value} key="0" index={0} prefix="tab">
          <Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ my: 3 }}>
                Project Budget Breakdown
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FundingButton
                  startIcon={<img src={FundingIcon} alt="Import" style={{ width: 18, height: 18 }} />}
                  onClick={handleOpen}
                >
                  Funding
                </FundingButton>
              </Box>
              <FundingDetailsModal open={fundingDialogOpen} onClose={handleClose} />
            </Box>
            <SummaryTable />
          </Grid>
        </TabPanel>
        {sections.map((section, i) => (
          <TabPanel value={value} key={section.id} index={i + 1} prefix="tab">
            <Grid container>
              <Grid item xs={12}>
                <FacilitiesAndServicesContextProvider>
                  <SectionTab
                    sectionId={section.id}
                    sectionName={section.name}
                    sectionDisplayName={section.displayName ?? section.name}
                    categories={section.categories}
                    readOnly={props.readOnly}
                  />
                </FacilitiesAndServicesContextProvider>
              </Grid>
            </Grid>
          </TabPanel>
        ))}
      </Box>
    </Grid>
  );
}

export default BudgetTabs;
