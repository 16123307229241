import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

import { DeleteCategoryButton } from './costing-delete';
import { useCostingReviewContext } from './costings-review-context';
import CostingsTable from './costings-table/costings-table';

import { CostingCategory } from '@/types';

function CostingsCategory(props: { sectionId: string; category: CostingCategory }) {
  const { category, sectionId } = props;
  const [categoryName, setCategoryName] = useState(category.name);

  const { updateCategory, deleteCategory } = useCostingReviewContext();

  useEffect(() => {
    const updatedCategory = {
      id: category.id,
      name: categoryName,
      columnDefinitions: category.columnDefinitions,
      rows: category.rows,
    };
    updateCategory(sectionId, updatedCategory);
  }, [category.columnDefinitions, category.id, category.rows, categoryName, sectionId, updateCategory]);

  return (
    <Box sx={{ flexGrow: 1, padding: 0, marginBottom: 2, ml: 4 }}>
      <Box>
        <TextField
          id={`cost-category-name-${category.id}`}
          label="Enter Cost Category"
          value={categoryName}
          onChange={(event) => setCategoryName(event.target.value)}
          sx={{ marginBottom: 2 }}
          size="small"
        />
      </Box>
      <Box>
        <CostingsTable sectionId={sectionId} category={category} />
      </Box>
      <Box sx={{ mt: 2 }}>
        <DeleteCategoryButton
          title="Delete Category?"
          buttonTitle="Delete Category"
          callback={() => deleteCategory(sectionId, category.id)}
        />
      </Box>
    </Box>
  );
}

export default CostingsCategory;
