import { Box, Button, Grid, Typography } from '@mui/material';

import NotFoundImage from '@/assets/images/404.svg';

function NotFound() {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid>
        <Box marginBottom="51px" display="flex" justifyContent="center" alignItems="center">
          <img src={NotFoundImage} width={630} height={473} alt="404" />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop={2}>
          <Typography variant="h6">Page you’re looking for doesn’t exist</Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop={2}>
          <Typography variant="body1" color="text.secondary">
            Seems you’ve entered an invalid url
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" marginTop="64px">
          <Button variant="contained" href="/funders">
            BACK TO HOME
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export default NotFound;
