import TableChartOutlined from '@mui/icons-material/TableChartOutlined';
import { Button } from '@mui/material';
import { GridAlignment, GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { isEmpty, omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import addDateTimeColumnDefinition from './components/table/datetime-column-definition';
import {
  AddExternalColumnDefinition,
  addDropdownColumnDefinition,
} from './components/table/dropdown-column-definition';
import addExternalFilterableColumnDefinition from './components/table/external-filterable-column-definition';
import addTableColumnDefinition from './components/table/table-column-definition';
import DeleteRowIcon from './features/section/costings-delete';

import {
  ColumnDefinition,
  ColumnValueType,
  CostEntity,
  DropdownColumnDefinition,
  ExternalColumnDefinition,
  FacilitiesAndServicesData,
  TableColumnDefinition,
} from '@/types';

const locale = process.env.REACT_APP_LOCALE ?? 'en-GB';

const rightAlignColumns = ['Project Cost', 'Funder Cost', 'Income', 'Surplus/Deficit'];
const alignValue = (name: string): GridAlignment => (rightAlignColumns.includes(name) ? 'right' : 'left');

function getCurrency(): string | undefined {
  return {
    'en-GB': 'GBP',
    'en-AU': 'AUD',
  }[locale];
}

const getNumberFormatOptions = (withCurrency: boolean, noRounding: boolean): Intl.NumberFormatOptions => {
  const options: Intl.NumberFormatOptions = {
    style: withCurrency ? 'currency' : 'decimal',
    minimumFractionDigits: noRounding ? 1 : 0,
    maximumFractionDigits: noRounding ? 1 : 0,
    useGrouping: true,
    signDisplay: 'never',
  };

  if (withCurrency) {
    options.currency = getCurrency();
  }

  return options;
};

const formatValueCell = (
  value: unknown,
  options: { withCurrency?: boolean } = {},
  columnName: string | undefined = undefined,
) => {
  if (typeof value !== 'number') {
    return String(value);
  }

  const { withCurrency = false } = options;
  const noRounding = columnName?.toLowerCase().includes('effort');

  const formattedValue = new Intl.NumberFormat(locale, getNumberFormatOptions(withCurrency, noRounding ?? false))
    .format(Math.abs(value))
    .replace(/^([\d,.]+)/, '$1 ')
    .replace(/\b([\d,.]+)$/, ' $1');

  return value < 0 ? `(${formattedValue})` : formattedValue;
};

const flattenRows = (
  node: CostEntity[],
  result: GridRowModel[] = [],
  depth = 0,
  parentHierarchy: string[] = [],
  excludedKeys: string[] = [],
): GridRowModel[] => {
  node.forEach((child) => {
    const currentHierarchy = [...parentHierarchy, child.Type];
    const rowData = {
      ...omit(child, excludedKeys),
      id: uuidv4(),
      depth,
      Hierarchy: currentHierarchy,
    };

    result.push(rowData);

    if (child.Children && !isEmpty(child.Children)) {
      flattenRows(child.Children, result, depth + 1, currentHierarchy);
    }
  });

  return result;
};

function getColumnDefinitions(
  columnDefinitions: ColumnDefinition[],
  readonly: boolean,
  onNestedTableButtonClick: (column: TableColumnDefinition) => void,
  onDeleteRow: (rowId: string) => void,
  onCostingTableButtonClick: () => void,
  hasCostingTable = true,
  facilitiesAndServicesData: FacilitiesAndServicesData[] = [],
  parentRow: GridRowModel = {},
): GridColDef[] {
  function isTimeConsumptionTable() {
    return columnDefinitions.some((column) => column.name === 'Consumption Units');
  }

  let columns = columnDefinitions.map((column: ColumnDefinition) => {
    const gridColumnDefinition = {
      field: column.name,
      headerName: column.displayName ?? column.name,
      headerClassName: 'grid-header-theme',
      cellClassName: 'grid-cell-theme',
      sortable: false,
      flex: 1,
      editable: true,
      headerAlign: alignValue(column.name),
      align: alignValue(column.name),
    };

    const handleNestedTableButtonClick = () => {
      onNestedTableButtonClick(column as TableColumnDefinition);
    };

    function setNumberColumn(params: GridRenderCellParams, field: string) {
      if (field === 'Consumption Units') {
        const unitCost = facilitiesAndServicesData.find((item) => item.name === parentRow.Item)?.unitCost;
        const projectCost = (params.value as number) * (unitCost ?? 0);

        return {
          ...params.row,
          'Consumption Units': params.value as number,
          'Project Cost': projectCost,
        } as GridRenderCellParams;
      }
      return { ...params.row, [field]: params.value as number } as GridRenderCellParams;
    }

    switch (column.$type) {
      case ColumnValueType.number:
        return {
          ...gridColumnDefinition,
          type: 'number',
          editable: column.name === 'Project Cost' && isTimeConsumptionTable() ? false : !readonly,
          renderCell: (params: GridRenderCellParams) =>
            formatValueCell(params.value, { withCurrency: false }, column.name),
          valueSetter: (params: GridRenderCellParams) => setNumberColumn(params, column.name),
        };
      case ColumnValueType.dropdown:
        return addDropdownColumnDefinition(column as DropdownColumnDefinition, {
          ...gridColumnDefinition,
          editable: !readonly,
        });
      case ColumnValueType.datetime:
        return addDateTimeColumnDefinition(readonly, gridColumnDefinition);
      case ColumnValueType.table:
        return addTableColumnDefinition(gridColumnDefinition, handleNestedTableButtonClick);
      case ColumnValueType.external:
        if ((column as ExternalColumnDefinition).filterable) {
          return addExternalFilterableColumnDefinition(gridColumnDefinition, column, readonly);
        }
        return AddExternalColumnDefinition(
          { ...gridColumnDefinition, editable: !readonly },
          column as ExternalColumnDefinition,
        );
      default:
        return { ...gridColumnDefinition, type: 'string', editable: !readonly };
    }
  });

  if (hasCostingTable) {
    columns = [
      ...columns,
      {
        field: 'Costing Table',
        headerClassName: 'grid-header-theme',
        cellClassName: 'grid-cell-theme',
        sortable: false,
        flex: 1,
        editable: false,
        headerAlign: 'left',
        align: 'left',
        headerName: 'Costing Table',
        renderCell: () => (
          <Button
            data-testid="costing-table-button"
            variant="contained"
            size="small"
            startIcon={<TableChartOutlined />}
            onClick={onCostingTableButtonClick}
          >
            Costing Table
          </Button>
        ),
      },
    ];
  }

  return [
    ...columns,
    {
      field: 'actions',
      headerName: 'Actions',
      headerClassName: 'grid-header-theme',
      sortable: false,
      editable: false,
      renderCell: (params: GridRenderCellParams) =>
        readonly || (
          <DeleteRowIcon
            name={(params.row as { Name: string }).Name}
            callback={() => onDeleteRow(params.id.toString())}
          />
        ),
    },
  ] as GridColDef[];
}

const getColumnDefaultValue = ($type: ColumnValueType, name: string) => {
  if (name === 'Location') {
    return 'Onsite';
  }
  if ($type === ColumnValueType.text || $type === ColumnValueType.dropdown || $type === ColumnValueType.external) {
    return '';
  }
  if ($type === ColumnValueType.datetime) {
    return dayjs();
  }
  if ($type === ColumnValueType.table) {
    return [];
  }
  return 0;
};

const costingGridColumnDefinition = {
  headerClassName: 'grid-header-theme',
  cellClassName: 'grid-cell-theme',
  sortable: false,
  flex: 1,
};

export {
  getColumnDefinitions,
  getColumnDefaultValue,
  costingGridColumnDefinition,
  alignValue,
  formatValueCell,
  flattenRows,
};
