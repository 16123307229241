import axios from 'axios';

import { trackException } from '@/application-insights';

export interface UploadsDto {
  id: number;
  fileName: string;
  uploadDate: string;
  link: string;
}

const BASE_URL = '/api/preaward';

const preawardApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-forwarded-host': window.location.hostname,
  },
});

preawardApi.interceptors.response.use(
  (response) => response,
  (error: Error) => trackException(error),
);

export enum UploadType {
  Staff,
  University,
  PayScale,
  NonStaff,
  Partner,
  OrganisationalUnit,
  FacilitiesAndServices,
  OverheadRates,
  StaffTypeRates,
  ProjectRoleRates,
  FunderPricingScheme,
  ScholarshipTypeRates,
}

const preawardAdminServiceApi = {
  getUploadTemplateFile: async (type: UploadType): Promise<boolean> => {
    try {
      const uploadType = UploadType[type];
      const path = type === UploadType.FunderPricingScheme ? 'Funder/pricing/upload/csv' : `${uploadType}/upload/csv`;
      const response = await preawardApi.get(path, {
        responseType: 'blob',
      });
      const fileName = `${uploadType.toString().toLowerCase()}-sample.csv`;
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
      return true;
    } catch (e) {
      return false;
    }
  },

  UploadFile: async (type: UploadType, file: File) => {
    const uploadType = UploadType[type];
    const formData = new FormData();
    const urlString = `/${uploadType}/upload/csv`;
    formData.append('file', file);
    const response = await preawardApi.post(urlString, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.status === 204;
  },

  UploadFunderPricingSchemeFile: async (file: File, funderId: string) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await preawardApi.post(`/Funder/${funderId}/pricing/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.status === 204;
  },
};

export default preawardAdminServiceApi;
