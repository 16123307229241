import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { Guid } from 'typescript-guid';

import CostingTableDialog from './costing-table-dialog';
import CostingsTable from './table';
import TimeTableDialog from './time-table-dialog';

import { Categories, ColumnDefinition, CostingCategory, Sections, TableColumnDefinition } from '@/types';
import { ActionType, useBudgetDispatchContext } from '@forms/contexts/budget-context';
import { getColumnDefaultValue, getColumnDefinitions } from '@forms/utils';

interface DataGridProps {
  sectionId: string;
  sectionName: string;
  sectionDisplayName: string;
  categories: CostingCategory[];
  readOnly: boolean;
}

function SectionTab(props: Readonly<DataGridProps>) {
  const { sectionId, sectionName, sectionDisplayName, categories, readOnly } = props;
  const dispatch = useBudgetDispatchContext();
  const [nestedTableField, setNestedTableField] = useState<string>('');
  const [nestedTableColDefs, setNestedTableColDefs] = useState<ColumnDefinition[]>([]);
  const [currentRow, setCurrentRow] = useState<GridRowModel>({});
  const [nestedDialogOpen, setNestedDialogOpen] = useState(false);
  const [costingTableDialogOpen, setCostingTableDialogOpen] = useState(false);
  const handleNestedDialogOpen = (colDefs: ColumnDefinition[], fieldName: string) => {
    setNestedTableColDefs(colDefs);
    setNestedTableField(fieldName);
    setNestedDialogOpen(true);
  };
  const handleNestedDialogClose = () => setNestedDialogOpen(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const { columnDefinitions, id: categoryId } = categories[selectedTab];

  const defaultRow = columnDefinitions.reduce(
    (acc, columnDefinition) => {
      const { name, $type } = columnDefinition;
      return { ...acc, [name]: getColumnDefaultValue($type, name) };
    },
    { id: '1' },
  );

  function onNestedTableButtonClick(column: TableColumnDefinition) {
    handleNestedDialogOpen(column.columnDefinitions, column.name);
  }

  function onCostingTableButtonClick() {
    setCostingTableDialogOpen(true);
  }

  function onDeleteRow(rowId: string) {
    dispatch({
      type: ActionType.DELETE_ROW,
      payload: { sectionId, categoryId, rowId },
    });
  }

  function processRowUpdate(newRow: GridRowModel) {
    dispatch({
      type: ActionType.UPDATE_ROW,
      payload: {
        sectionId,
        categoryId,
        existingRowId: newRow.id as string,
        updatedRow: newRow,
      },
    });
    return newRow;
  }

  function addRow(selectedCategoryId: string, newRow: GridRowModel) {
    dispatch({
      type: ActionType.ADD_ROW,
      payload: {
        sectionId,
        categoryId: selectedCategoryId,
        row: newRow,
      },
    });
  }

  function subTabs() {
    return props.categories?.length > 1 ? (
      <Grid item sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }} xs={12}>
        <Tabs value={selectedTab} onChange={handleChange}>
          {props.categories?.map((cat, i) => (
            <Tab label={cat.name} key={cat.id} id={`subTab-${i + 1}`} aria-controls={`tabpanel-${i}`} />
          ))}
        </Tabs>
      </Grid>
    ) : null;
  }

  function hasMultipleCategories() {
    return props.categories.length > 1;
  }

  return (
    <Grid container sx={{ pt: 2 }}>
      <Grid item xs={9}>
        <Typography variant="h6">{sectionDisplayName}</Typography>
      </Grid>
      <Grid item xs={3}>
        {!hasMultipleCategories() &&
          props.categories.map((category) => (
            <Box key={category.id} display="flex" justifyContent="flex-end">
              {!readOnly && (
                <Button
                  variant="outlinedAction"
                  id="addRowButton"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    const newRow = { ...defaultRow };
                    newRow.id = Guid.create().toString();
                    return addRow(category.id, newRow);
                  }}
                >
                  Add
                </Button>
              )}
            </Box>
          ))}
      </Grid>
      {subTabs()}
      {props.categories.map((category, i) => (
        <Grid
          key={category.id}
          container
          style={{
            visibility: selectedTab === i ? 'visible' : 'hidden',
            height: selectedTab === i ? 'auto' : 0,
          }}
        >
          {hasMultipleCategories() && (
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h6">{category.name}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" justifyContent="flex-end">
                  {!readOnly && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        const newRow = { ...defaultRow };
                        newRow.id = Guid.create().toString();
                        return addRow(category.id, newRow);
                      }}
                    >
                      Add
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <CostingsTable
              columns={getColumnDefinitions(
                category.columnDefinitions,
                readOnly,
                onNestedTableButtonClick,
                onDeleteRow,
                onCostingTableButtonClick,
                !(sectionName === Sections.Partners && category.name === Categories.NonStaff),
              )}
              rows={category.rows}
              onProcessRowUpdate={(newRow) => processRowUpdate(newRow)}
              onRowClick={(row) => setCurrentRow(row)}
              uniqueKey="id"
            />
          </Grid>
          <TimeTableDialog
            sectionId={sectionId}
            categoryId={category.id}
            columns={nestedTableColDefs}
            parentField={nestedTableField}
            parentRow={currentRow}
            open={nestedDialogOpen}
            handleClose={handleNestedDialogClose}
            readOnly={readOnly}
          />
          <CostingTableDialog
            sectionName={sectionName}
            open={costingTableDialogOpen}
            handleClose={() => setCostingTableDialogOpen(false)}
            parentRow={currentRow}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default SectionTab;
