import { Box, Typography, styled } from '@mui/material';
import React from 'react';

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  cursor: 'pointer',
});

const Button = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '81px',
  width: '81px',
  padding: '7px 10px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '6px',
  alignSelf: 'stretch',
  borderRadius: '12px',
  backgroundColor: 'rgba(36, 104, 196, 0.08)',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.02)',
  marginBottom: '12px',
  '&:hover': {
    backgroundColor: 'rgba(36, 104, 196, 0.12)',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: 'center',
}));

interface UploadsActionButtonProps {
  onClick: () => void;
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
}

function UploadsActionButton({ onClick, title, subtitle, icon }: Readonly<UploadsActionButtonProps>) {
  return (
    <StyledBox onClick={onClick}>
      <Button tabIndex={0} aria-label={title}>
        {icon}
      </Button>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', alignItems: 'start', marginLeft: 2 }}>
        <Title variant="subtitle1">{title}</Title>
        <Subtitle variant="body2">{subtitle}</Subtitle>
      </Box>
    </StyledBox>
  );
}

export default React.memo(UploadsActionButton);
