import { ChevronRight } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

import { TabConfig } from './upload-manager';

export interface ConfigTile {
  title: string;
  description: string;
  url: string;
  state?: TabConfig[];
}

function ConfigurationTile({ title, description, url, state }: Readonly<ConfigTile>) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper
        elevation={0}
        sx={{
          height: '100%',
          borderRadius: 2,
          px: 2,
          pb: 1.5,
          pt: 1,
          border: '1px solid',
          borderColor: 'grey.300',
          cursor: 'pointer',
          '&:hover': {
            bgcolor: 'action.hover',
          },
        }}
        data-testid="config-tile"
        onClick={() => navigate(url, { state })}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600 }}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: '0.75rem', maxLines: 2, fontWeight: 400 }}
            >
              {description}
            </Typography>
          </Box>
          <IconButton color="secondary" sx={{ fontSize: '0.875rem' }}>
            <ChevronRight fontSize="small" />
          </IconButton>
        </Box>
      </Paper>
    </Grid>
  );
}

export default React.memo(ConfigurationTile);
