import { Route, Routes } from 'react-router-dom';

import AdminPage from './admin/pages/homepage';
import UploadsTileTabs from './admin/pages/uploads-tile-tabs';

import NotFound from '@/components/not-found';
import { BudgetContextProvider } from '@/forms/contexts/budget-context';
import Budget from '@/forms/pages/budget/budget';
import CreateFunder from '@/funders/create-funder';
import FunderList from '@/funders/funder-list';
import UpdateFunder from '@/funders/update-funder';
import CostingsReview from '@/review/costings-review';
import { CostingReviewContextProvider } from '@/review/costings-review-context';

function Main() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="/costings-review"
        element={
          <CostingReviewContextProvider>
            <CostingsReview />
          </CostingReviewContextProvider>
        }
      />
      <Route path="/funders" element={<FunderList />} />
      <Route path="/funder" element={<CreateFunder />} />
      <Route path="/funder/:id" element={<UpdateFunder />} />
      <Route
        path="/budget"
        element={
          <BudgetContextProvider>
            <Budget />
          </BudgetContextProvider>
        }
      />
      <Route path="/admin" element={<AdminPage />} />
      <Route path="/admin/staff" element={<UploadsTileTabs />} />
      <Route path="/admin/facilities-services" element={<UploadsTileTabs />} />
      <Route path="/admin/partners" element={<UploadsTileTabs />} />
      <Route path="/admin/organisational-units" element={<UploadsTileTabs />} />
      <Route path="/admin/non-staff" element={<UploadsTileTabs />} />
      <Route path="/admin/inflation-rates" element={<UploadsTileTabs />} />
      <Route path="/admin/funders-pricing-scheme" element={<UploadsTileTabs />} />
      <Route path="/admin/scholarship-type-rate" element={<UploadsTileTabs />} />
    </Routes>
  );
}
export default Main;
