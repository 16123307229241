import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

const applicationInsightsConnectionString = process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: applicationInsightsConnectionString ?? 'InstrumentationKey=b7170927-2d1c-44f1-acec-59f4e1751c11',
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxPerfTracking: true,
    isBrowserLinkTrackingEnabled: true,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();

function AzureAppInsights(props: { children: JSX.Element }): JSX.Element {
  return <AppInsightsContext.Provider value={reactPlugin}>{props.children}</AppInsightsContext.Provider>;
}

function trackException(error: Error) {
  appInsights.trackException({ error, severityLevel: SeverityLevel.Error });
}

export { appInsights, trackException };
export default withAITracking(reactPlugin, AzureAppInsights);
