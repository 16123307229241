import { Theme, alpha } from '@mui/material';
import { DataGridPro, DataGridProProps, GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import { memoize, omit } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { useBudgetStateContext } from '@/forms/contexts/budget-context';
import { CostEntity } from '@/types';
import { alignValue, costingGridColumnDefinition, flattenRows, formatValueCell } from '@forms/utils';

const EXCLUDED_KEYS = ['Children', 'Type'] as const;
const DEFAULT_EXPANSION_DEPTH = 2;
const ROW_HEIGHT = 48;
const HEADER_HEIGHT = 56;

const getStyles = (theme: Theme) => ({
  '& .MuiDataGrid-root': {
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
  },
  '.hierarchyLevel1': {
    bgcolor: alpha(theme.palette.primary.main, 0.08),
    fontWeight: 'bold',
  },
  '.hierarchyLevel2': {
    bgcolor: alpha(theme.palette.primary.main, 0.06),
    fontWeight: 600,
  },
  '.hierarchyLevel3': {
    bgcolor: alpha(theme.palette.primary.main, 0.04),
  },
  '.hierarchyLevel4': {
    bgcolor: alpha(theme.palette.primary.main, 0.02),
  },
  '& .MuiDataGrid-cell': {
    borderColor: alpha(theme.palette.primary.main, 0.1),
    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeader': {
    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },
});

const createColumn = (key: string): GridColDef => ({
  ...costingGridColumnDefinition,
  field: key,
  headerName: key,
  type: 'number',
  flex: 0.15,
  headerAlign: alignValue(key),
  align: alignValue(key),
  renderCell: (params: GridRenderCellParams) => formatValueCell(params.value as number, { withCurrency: true }),
});

const groupingColDef: DataGridProProps['groupingColDef'] = {
  ...costingGridColumnDefinition,
  headerName: 'Type',
  flex: 0.4,
  hideDescendantCount: true,
};

export const getDepthClass = (depth: number): string => `hierarchyLevel${depth + 1}`;

const extractColumns = memoize((data: CostEntity[]): GridColDef[] => {
  const sample = data[0];
  if (!sample) return [];

  return Object.keys(omit(sample, EXCLUDED_KEYS)).map(createColumn);
});

function SummaryTable() {
  const { summaryTable } = useBudgetStateContext();
  const isTestEnv = process.env.NODE_ENV === 'test';

  const columns = useMemo(() => extractColumns(summaryTable), [summaryTable]);
  const rows = useMemo(() => flattenRows(summaryTable), [summaryTable]);

  const getRowClassName = useCallback((params: { row: GridRowModel }) => {
    const { depth } = params.row as { depth: number };
    return getDepthClass(depth);
  }, []);

  const getTreeDataPath = useCallback((row: GridRowModel) => (row as { Hierarchy: string[] }).Hierarchy, []);

  return (
    <DataGridPro
      sx={(theme: Theme) => getStyles(theme)}
      getRowClassName={getRowClassName}
      treeData
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      defaultGroupingExpansionDepth={DEFAULT_EXPANSION_DEPTH}
      autoHeight
      rowHeight={ROW_HEIGHT}
      columnHeaderHeight={HEADER_HEIGHT}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      hideFooter
      disableColumnReorder
      disableVirtualization={isTestEnv}
      density="compact"
    />
  );
}

export default React.memo(SummaryTable);
