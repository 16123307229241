import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// eslint-disable-next-line import/no-cycle
import AddColumnDialog from './add-column-dialog';

import CustomCostingsTable from '@/review/costings-table/table';
import { ColumnDefinition, Status, TableColumnDefinition } from '@/types';

function TableColumnForm(
  props: Readonly<{
    columns: ColumnDefinition[];
    nestedColumns: ColumnDefinition[];
    columnToEdit?: ColumnDefinition;
    sectionId: string;
    categoryId: string;
    addColumn: (column: ColumnDefinition) => void;
    deleteColumn: (columnName: string) => void;
    updateColumn: (oldColumnName: string, column: ColumnDefinition) => void;
  }>,
) {
  const {
    control,
    formState: { errors },
  } = useFormContext<TableColumnDefinition>();

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<Status>(Status.ADD);
  const [columnToEdit, setColumnToEdit] = useState<ColumnDefinition>();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function ValidateName(name: string): boolean {
    let existingColumns = props.columns;
    if (props.columnToEdit?.name !== '') {
      existingColumns = existingColumns.filter((column) => column.name !== props.columnToEdit?.name);
    }

    if (existingColumns.find((column) => column.name === name)) {
      return false;
    }
    return true;
  }

  const rows = [{ id: 1 }];

  const handleDeleteColumn = (columnName: string) => {
    props.deleteColumn(columnName);
  };

  const handleEditColumn = (columnName: string) => {
    setStatus(Status.UPDATE);
    setColumnToEdit(props.nestedColumns.find((name) => name.name === columnName));
    handleOpen();
  };

  const handleAddColumn = (sectionId: string, categoryId: string, column: ColumnDefinition) => {
    props.addColumn(column);
  };

  const handleUpdateColumn = (
    sectionId: string,
    categoryId: string,
    oldColumnName: string,
    column: ColumnDefinition,
  ) => {
    props.updateColumn(oldColumnName, column);
  };

  return (
    <Grid container>
      <Grid xs={12} item>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2">Table field allows you to enter nested data value</Typography>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box sx={{ mb: 2 }}>
          <Divider />
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Controller
          name="name"
          defaultValue=""
          rules={{
            required: true,
            validate: {
              nameCheck: (v: string) => ValidateName(v) || 'Column name already exists',
            },
          }}
          control={control}
          render={({ field }) => (
            <TextField
              id="columnName"
              label="Enter Column Name"
              fullWidth
              size="small"
              sx={{ mb: 2 }}
              error={!!errors.name}
              {...field}
            />
          )}
        />
        <Controller
          name="displayName"
          defaultValue=""
          rules={{
            required: false,
          }}
          control={control}
          render={({ field }) => (
            <TextField
              id="columnDisplayName"
              label="Enter Column Display Name"
              fullWidth
              size="small"
              sx={{ mb: 2 }}
              error={!!errors.name}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid xs={12} item sx={{ mb: 3 }}>
        <Typography color="error.main">{errors.name?.message}</Typography>
      </Grid>
      <Grid xs={11} item>
        <CustomCostingsTable
          deleteColumn={handleDeleteColumn}
          editColumn={handleEditColumn}
          columns={props.nestedColumns}
          rows={rows}
        />
      </Grid>
      <Grid xs={1} item>
        <IconButton
          id="addNestedColumnButton"
          onClick={() => {
            setStatus(Status.ADD);
            setColumnToEdit(undefined);
            handleOpen();
          }}
          sx={{ marginTop: 2 }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Grid>
      <Grid xs={12} item>
        <AddColumnDialog
          status={status}
          columnToEdit={columnToEdit}
          sectionId={props.sectionId}
          categoryId={props.categoryId}
          columns={props.nestedColumns}
          open={open}
          handleClose={handleClose}
          handleAdd={handleAddColumn}
          handleUpdate={handleUpdateColumn}
        />
      </Grid>
    </Grid>
  );
}

export default TableColumnForm;
