import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import { ConfirmOptions, useConfirm } from 'material-ui-confirm';

interface DeleteButtonProps {
  title: string;
  buttonTitle: string;
  callback: () => void;
}

interface DeleteMenuItemProps {
  title: string;
  isDisabled: boolean;
  callback: () => void;
}

function CreateConfirmOptions(title: string): ConfirmOptions {
  return {
    title: '',
    content: (
      <Grid container paddingRight={4} paddingBottom={2}>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center" paddingTop={2}>
            <ErrorOutlineIcon color="warning" fontSize="large" />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box>
            <Typography variant="h6">{title}</Typography>
            <Grid>
              <Grid id="delete-column-dialog-desription">
                <Typography variant="body2">
                  Are you sure you want to delete this? This action cannot be undone.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    ),
    cancellationText: 'No',
    cancellationButtonProps: {
      variant: 'outlined',
      color: 'primary',
    },
    confirmationText: 'Yes, Delete',
    confirmationButtonProps: {
      variant: 'contained',
      color: 'warning',
    },
  };
}

export function DeleteCategoryButton(props: DeleteButtonProps) {
  const confirm = useConfirm();
  const options = CreateConfirmOptions(props.title);
  return (
    <Button
      variant="contained"
      color="warning"
      onClick={() => {
        confirm(options)
          .then(() => props.callback())
          .catch(() => {});
      }}
    >
      {props.buttonTitle}
    </Button>
  );
}

export function DeleteSectionIcon(props: { callback: () => void }) {
  const confirm = useConfirm();
  const options = CreateConfirmOptions('Delete Section?');
  return (
    <IconButton
      onClick={() => {
        confirm(options)
          .then(() => props.callback())
          .catch(() => {});
      }}
    >
      <DeleteIcon color="warning" />
    </IconButton>
  );
}

export function DeleteColumnMenuItem(props: DeleteMenuItemProps) {
  const confirm = useConfirm();
  const options = CreateConfirmOptions(props.title);

  return (
    <MenuItem
      disabled={props.isDisabled}
      onClick={() => {
        confirm(options)
          .then(() => props.callback())
          .catch(() => {});
      }}
    >
      Delete
    </MenuItem>
  );
}
